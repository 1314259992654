import {
  AcUnitOutlined,
  AccessibilityNewOutlined,
  AccountBalanceOutlined,
  Brightness4Outlined,
  BuildOutlined,
  BusinessCenterOutlined,
  DirectionsBikeOutlined,
  GroupWorkOutlined,
  LocalFloristOutlined,
  LocalHospitalOutlined,
  LocalShippingOutlined,
  PersonAddOutlined,
  PersonRemoveOutlined,
  PublicOutlined,
  SchoolOutlined,
  SupervisedUserCircleOutlined,
  WavesOutlined,
} from "@mui/icons-material";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFriends } from "state";
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";
import { host } from "host";
import {
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";
import "./style.css";

const Friend = ({
  friendId,
  name,
  subtitle,
  userPicturePath,
  sdg,
  createdAt,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const friends = useSelector((state) => state.user.friends);

  const { palette } = useTheme();
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  const isFriend = friends.find((friend) => friend._id === friendId);

  const patchFriend = async () => {
    const response = await fetch(`${host}/users/${_id}/${friendId}`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };
  const sdgIcons = {
    "No Poverty": (
      <div className="img">
        <img src="../assets/sdg 1.svg" alt="" style={{width:"45px"}} />
      </div>
    ),
    "Zero Hunger": (
      <div className="img">
        <img src="../assets/sdg 2.svg" alt="" />
      </div>
    ),
    "Good Health and Well-being": (
      <div className="img">
        <img src="../assets/sdg 3.svg" alt="" />
      </div>
    ),
    "Quality Education": (
      <div className="img">
        <img src="../assets/sdg 4.svg" alt="" />
      </div>
    ),
    "Gender Equality": (
      <div className="img">
        <img src="../assets/sdg 5.svg" alt="" />
      </div>
    ),
    "Clean Water and Sanitation": (
      <div className="img">
        <img src="../assets/sdg-6.svg" alt="" />
      </div>
    ),
    "Affordable and Clean Energy": (
      <div className="img">
        <img src="../assets/sdg 7.svg" alt="" />
      </div>
    ),
    "Decent Work and Economic Growth": (
      <div className="img">
        <img src="../assets/sdg 8.svg" alt="" />
      </div>
    ),
    "Industry, Innovation, and Infrastructure": (
      <div className="img">
        <img src="../assets/sdg 9.svg" alt="" />
      </div>
    ),
    "Reduced Inequality": (
      <div className="img">
        <img src="../assets/sdg 10.svg" alt="" />
      </div>
    ),
    "Sustainable Cities and Communities": (
      <div className="img">
        <img src="../assets/sdg 11.svg" alt="" />
      </div>
    ),
    "Responsible Consumption and Production": (
      <div className="img">
        <img src="../assets/sdg 12.svg" alt="" />
      </div>
    ),
    "Climate Action": (
      <div className="img">
        <img src="../assets/sdg 13.svg" alt="" />
      </div>
    ),
    "Life Below Water": (
      <div className="img">
        <img src="../assets/sdg 14.svg" alt="" />
      </div>
    ),
    "Life on Land": (
      <div className="img">
        <img src="../assets/sdg 15.svg" alt="" />
      </div>
    ),
    "Peace and Justice Strong Institutions": (
      <div className="img">
        <img src="../assets/sdg16.svg" alt="" />
      </div>
    ),
    "Partnerships to achieve the Goal": (
      <div className="img">
        <img src="../assets/sdg 17.svg" alt="" />
      </div>
    ),
  };

  // Get the corresponding icon for the SDG name
  const icon = sdgIcons[sdg];
  const getTimeAgo = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const minutesDiff = differenceInMinutes(currentDate, createdDate);
    if (minutesDiff < 60) {
      return `${minutesDiff} minute${minutesDiff !== 1 ? "s" : ""} ago`;
    }
    const hoursDiff = differenceInHours(currentDate, createdDate);
    if (hoursDiff < 24) {
      return `${hoursDiff} hour${hoursDiff !== 1 ? "s" : ""} ago`;
    }
    const daysDiff = differenceInDays(currentDate, createdDate);
    return `${daysDiff} day${daysDiff !== 1 ? "s" : ""} ago`;
  };

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <UserImage image={userPicturePath} size="55px" />
        <Box
          onClick={() => {
            navigate(`/profile/${friendId}`);
            navigate(0);
          }}
        >
          <Typography
            color={main}
            variant="h5"
            fontWeight="500"
            sx={{
              "&:hover": {
                color: palette.primary.light,
                cursor: "pointer",
              },
            }}
          >
            {name}
          </Typography>
          <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography>
          {/* {createdAt} */}
          {createdAt && (
            <Typography color={medium} fontSize="0.75rem">
              {getTimeAgo(createdAt)}
            </Typography>
          )}
        </Box>
      </FlexBetween>
      {icon && (
        <IconButton
          className="icon"
          // onClick={() => patchFriend()}
          sx={{ p: "0rem" }}
        >
          {/* {isFriend ? (
          <PersonRemoveOutlined sx={{ color: primaryDark }} />
        ) : (
          <PersonAddOutlined sx={{ color: primaryDark }} />
        )} */}
          {/* Render the SDG icon */}
          {icon}
        </IconButton>
      )}
    </FlexBetween>
  );
};

export default Friend;
