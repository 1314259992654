import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectedSdgs } from "state";
import "./style.css";

const TopBar = () => {
  const dispatch = useDispatch();

  // Mapping SDGs to their respective images
  const sdgImages = {
    "No Poverty": "./assets/sdg 1.svg",
    "Zero Hunger": "./assets/sdg 2.svg",
    "Good Health and Well-being": "./assets/sdg 3.svg",
    "Quality Education": "./assets/sdg 4.svg",
    "Gender Equality": "./assets/sdg 5.svg",
    "Clean Water and Sanitation": "./assets/sdg-6.svg",
    "Affordable and Clean Energy": "./assets/sdg 7.svg",
    "Decent Work and Economic Growth": "./assets/sdg 8.svg",
    "Industry, Innovation, and Infrastructure": "./assets/sdg 9.svg",
    "Reduced Inequality": "./assets/sdg 10.svg",
    "Sustainable Cities and Communities": "./assets/sdg 11.svg",
    "Responsible Consumption and Production": "./assets/sdg 12.svg",
    "Climate Action": "./assets/sdg 13.svg",
    "Life Below Water": "./assets/sdg 14.svg",
    "Life on Land": "./assets/sdg 15.svg",
    "Peace and Justice Strong Institutions": "./assets/sdg16.svg",
    "Partnerships to achieve the Goal": "./assets/sdg 17.svg",
  };

  // SDGs array
  const sdgs = Object.keys(sdgImages);

  // State to track selected SDGs
  const [selectedSdg, setSelectedSdg] = useState([]);

  // Function to handle SDG selection
  const handleSdgSelection = (sdg) => {
    // Check if the SDG is already selected
    const isSdgSelected = selectedSdg.includes(sdg);

    // Toggle the selected state
    if (isSdgSelected) {
      setSelectedSdg(selectedSdg.filter((selected) => selected !== sdg));
    } else {
      setSelectedSdg([...selectedSdg, sdg]);
    }
  };

  // Dispatch selected SDGs to Redux store when selection changes
  useEffect(() => {
    dispatch(setSelectedSdgs(selectedSdg));
  }, [selectedSdg, dispatch]);

  return (
    <div className="top-bar">
      <div className="sdg-options">
        {sdgs.map((sdg) => (
          <button
            key={sdg}
            className={`sdg-button ${
              selectedSdg.includes(sdg) ? "active" : ""
            }`}
            onClick={() => handleSdgSelection(sdg)}
          >
            <img src={sdgImages[sdg]} alt={sdg} className="sdg-image" />
            <span>{sdg}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TopBar;
