import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useDispatch, useSelector } from "react-redux";
import UserImage from "./UserImage";
import {
  AccountBalance,
  AssessmentOutlined,
  CurrencyExchangeOutlined,
  DarkMode,
  DarkModeOutlined,
  HelpCenterOutlined,
  HelpCenterRounded,
  InfoOutlined,
  LightMode,
  LightModeOutlined,
  LogoutOutlined,
  ManageAccountsOutlined,
  MenuOutlined,
  Notifications,
  NotificationsActiveOutlined,
  Settings,
  SettingsOutlined,
} from "@mui/icons-material";
import FlexBetween from "./FlexBetween";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { setMode, setLogout } from "state";

export default function AnchorTemporaryDrawer({ user }) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary;
  const alt = theme.palette.background.alt;

  const [state, setState] = React.useState({
    left: false,
  });
  const { palette } = useTheme();
  const navigate = useNavigate();
  const medium = palette.neutral.medium;

  console.log({ user });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
        <FlexBetween
          gap="0.5rem"
          pb="1.1rem"
          pl="0.8rem"
          pt="0.5rem"
          onClick={() => navigate(`/profile/${user._id}`)}
        >
          <FlexBetween gap="1rem">
            <UserImage image={user.picturePath} />
            <Box>
              <Typography
                variant="h4"
                color={dark}
                fontWeight="500"
                sx={{
                  "&:hover": {
                    color: palette.primary.light,
                    cursor: "pointer",
                  },
                }}
              >
                {user.firstName} {user.lastName}
              </Typography>

              {/* <Typography color={medium}>{friends.length} friends</Typography> */}
              <Typography color={medium}>{user.userType}</Typography>
            </Box>
          </FlexBetween>
          {/* <ManageAccountsOutlined /> */}
        </FlexBetween>
        <Link to="/myProfile">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <ManageAccountsOutlined sx={{ fontSize: "25px" }} />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
          </ListItem>
        </Link>
        {/* <Divider /> */}
        {/* {["Assesment services", "Social Lancer"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? (
                  <AssessmentOutlined />
                ) : (
                  // <img src="../assets/445415-200.png" width={30} />
                  <CurrencyExchangeOutlined />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <NotificationsActiveOutlined sx={{ fontSize: "25px" }} />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <InfoOutlined sx={{ fontSize: "25px" }} />
            </ListItemIcon>
            <ListItemText primary="About us" />
          </ListItemButton>
        </ListItem>
        {/* <Divider /> */}

        {/* <Divider /> */}
        {/* <ListItem disablePadding onClick={() => dispatch(setMode())}>
          <ListItemButton>
            <ListItemIcon>
              {theme.palette.mode === "dark" ? (
                <DarkModeOutlined sx={{ fontSize: "25px" }} />
              ) : (
                <LightModeOutlined sx={{ fontSize: "25px" }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={theme.palette.mode === "dark" ? "Dark" : "Light"}
            />
          </ListItemButton>
        </ListItem> */}
      </List>
      <Divider />
      <List>
        {["Privacy and Policy"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemIcon>
                {index % 2 === 0 ? (
                  <SettingsOutlined />
                ) : (
                  <HelpCenterOutlined />
                )}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
        <ListItem disablePadding onClick={() => dispatch(setMode())}>
          <ListItemButton>
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText onClick={() => dispatch(setLogout())}>
              Logout
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuOutlined />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
