import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  MoreHorizOutlined,
  CloseOutlined,
  CancelOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  InputBase,
  useTheme,
  Button,
  IconButton,
  useMediaQuery,
  Modal,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import FlexBetween from "components/FlexBetween";
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";
import UserImage from "components/UserImage";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import { io } from "socket.io-client";
import { setSocket } from "state";
import { host } from "host";
import ReactQuill from "react-quill";
import "./post.css";
import app from "firebase.js";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "@firebase/storage";

import Friend from "components/Friend";
const MyPostWidget = ({ picturePath }) => {
  const dispatch = useDispatch();
  const [isImage, setIsImage] = useState(false);
  const [isSdg, setIsSdg] = useState(false);
  const [selectedSdg, setSelectedSdg] = useState("");
  const [image, setImage] = useState(null);
  const [downloadU, setDownloadU] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(downloadU);
  const sdgs = [
    "No Poverty",
    "Zero Hunger",
    "Good Health and Well-being",
    "Quality Education",
    "Gender Equality",
    "Clean Water and Sanitation",
    "Affordable and Clean Energy",
    "Decent Work and Economic Growth",
    "Industry, Innovation, and Infrastructure",
    "Reduced Inequality",
    "Sustainable Cities and Communities",
    "Responsible Consumption and Production",
    "Climate Action",
    "Life Below Water",
    "Life on Land",
    "Peace and Justice Strong Institutions",
    "Partnerships to achieve the Goal",
  ];
  const [post, setPost] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");

  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;
  // const [socket, setSocket] = useState(null);
  const socket = useRef();
  useEffect(() => {
    const current = io(`${host}`);
    socket.current = current;
    dispatch(setSocket({ isConnected: true })); // Dispatch serializable data instead of the entire socket instance
    // console.log(socket.current);
  }, [dispatch]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const uploadFile = (file) => {
    const storage = getStorage(app);
    const folder = "image/";
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          setDownloadU(downloadURL);
        });
      }
    );
  };
  const handlePost = async () => {
    try {
      const formData = new FormData();
      formData.append("userId", _id);
      formData.append("title", post);
      formData.append("summary", summary);
      formData.append("description", content);
      if (image) {
        formData.append("picturePath", downloadU); // Use downloadURL here
        // formData.append("picturePath", image.name); // Optional: Keep the original filename
      }
      formData.append("selectedSdg", JSON.stringify(selectedSdg)); // Append the selected SDG here

      const response = await fetch(`${host}/posts`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) {
        throw Error(response.message); // Throw an error if the request was not successful
      }

      const newPost = await response.json();

      if (socket.current) {
        socket.current.emit("new_post", newPost);
      }

      setImage(null);
      setPost("");
      setIsSdg(null);
      setSelectedSdg("");

      // Optionally, display a success message to the user
      toast.success(response.status, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      // Handle errors
      console.error("Error creating post:", error.error);
      setIsSdg(true);
      // Display an error toast to the user
      toast.error("Please select the sdg", error.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (image) {
      uploadFile(image); // Call uploadFile here with the image object
    }
  }, [image]);
  const user = useSelector((state) => state.user);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleCrossModal = () => {
    setSummary("");
    setContent("");
    setPost("");
    setIsModalOpen(false);
  };
  const isMobile = useMediaQuery("(min-width: 600px)");

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <WidgetWrapper>
        <FlexBetween gap="1.5rem">
          <UserImage image={picturePath} />
          <InputBase
            placeholder="What's on your mind..."
            onChange={(e) => setPost(e.target.value)}
            value={post}
            sx={{
              width: "100%",
              backgroundColor: palette.neutral.light,
              borderRadius: "2rem",
              padding: "1rem 2rem",
            }}
            onClick={handleInputClick} // Open modal when input is clicked
          />
          <Modal open={isModalOpen} onClose={handleCloseModal}>
            <Box
              sx={{
                position: "absolute",
                width: isMobile ? 1000 : 600, // Adjust width for mobile screens
                // Adjust height for mobile screens
                maxWidth: "100%", // Ensure modal doesn't exceed screen width on mobile
                maxHeight: "100%", // Ensure modal doesn't exceed screen height on mobile
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 0,
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "0 0 10px 0",
                overflowY: "scroll",
              }}
            >
              <div
                style={{
                  border: "2px solid green",
                  // marginTop: "60px",
                  marginRight: "7px",
                  marginTop: "7px",
                  marginLeft: "7px",
                  borderRadius: "15px",
                  padding: "40px",
                }}
              >
                {/* border: 2px solid green; margin-top: 7px; margin-left: 7px;
                border-radius: 15px; padding: 40px; margin-right: 7px; */}
                <div className="friend">
                  <Friend
                    friendId={user._id}
                    sdg={sdgs}
                    name={user.firstName}
                    subtitle={user.userType}
                    userPicturePath={picturePath}
                    // createdAt={createdAt}
                  />
                  <IconButton style={{ border: "100px", borderColor: "#000" }}>
                    <CancelOutlined
                      onClick={handleCrossModal}
                      style={{ fontSize: "35px" }}
                    />
                  </IconButton>
                </div>
                <div>
                  <InputBase
                    placeholder="Enter the title of the Post"
                    onChange={(e) => setPost(e.target.value)}
                    value={post}
                    sx={{
                      width: "100%",
                      backgroundColor: palette.neutral.light,
                      borderRadius: "2rem",
                      padding: "1rem 2rem",
                      margin: "20px 0",
                    }}
                  />
                  <InputBase
                    placeholder="Enter the summary of the Post"
                    onChange={(e) => setSummary(e.target.value)}
                    value={summary}
                    sx={{
                      width: "100%",
                      backgroundColor: palette.neutral.light,
                      borderRadius: "2rem",
                      padding: "1rem 2rem",
                      margin: "20px 0",
                    }}
                  />

                  <ReactQuill
                    value={content}
                    onChange={(NewValue) => setContent(NewValue)}
                    modules={modules}
                    formats={formats}
                    style={{ padding: "20px" }}
                  />

                  {/* <h2>profile content</h2>
              <h4>post content</h4>
              <p>- Vision </p>
              <p>- outcome</p>
            <p>- status</p> */}
                </div>
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    width: "50%",
                    marginLeft: "25%",
                    color: palette.background.alt,
                    backgroundColor: palette.primary.main,
                    borderRadius: "3rem",
                  }}
                >
                  SAVE
                </Button>
              </div>
            </Box>
          </Modal>
        </FlexBetween>
        {isImage && (
          <Box
            border={`1px solid ${medium}`}
            borderRadius="5px"
            mt="1rem"
            p="1rem"
          >
            <Dropzone
              acceptedFiles=".jpg,.jpeg,.png"
              multiple={false}
              onDrop={(acceptedFiles) => setImage(acceptedFiles[0])}
            >
              {({ getRootProps, getInputProps }) => (
                <FlexBetween>
                  <Box
                    {...getRootProps()}
                    border={`2px dashed ${palette.primary.main}`}
                    p="1rem"
                    width="100%"
                    sx={{ "&:hover": { cursor: "pointer" } }}
                  >
                    <input {...getInputProps()} />
                    {!image ? (
                      <p>Add Image Here</p>
                    ) : (
                      <FlexBetween>
                        <Typography>{image.name}</Typography>
                        <EditOutlined />
                      </FlexBetween>
                    )}
                  </Box>
                  {image && (
                    <IconButton
                      onClick={() => setImage(null)}
                      sx={{ width: "15%" }}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  )}
                </FlexBetween>
              )}
            </Dropzone>
          </Box>
        )}
        {isSdg && (
          <Box
            border={`1px solid ${medium}`}
            borderRadius="5px"
            mt="1rem"
            p="1rem"
          >
            <FlexBetween>
              {/* SDG Dropdown */}
              <Select
                value={selectedSdg}
                onChange={(event) => setSelectedSdg(event.target.value)}
                sx={{ width: "100%" }}
              >
                <MenuItem value="">Select SDG</MenuItem>
                {sdgs.map((sdg) => (
                  <MenuItem key={sdg} value={sdg}>
                    {sdg}
                  </MenuItem>
                ))}
              </Select>
              {/* SDG Image */}
              {selectedSdg && (
                <IconButton
                  onClick={() => {
                    setSelectedSdg("");
                    setIsSdg(!isSdg);
                  }}
                  sx={{ width: "15%" }}
                >
                  <DeleteOutlined />
                </IconButton>
              )}
            </FlexBetween>
          </Box>
        )}

        <Divider sx={{ margin: "1.25rem 0" }} />

        <FlexBetween>
          <FlexBetween gap="0.25rem" onClick={() => setIsImage(!isImage)}>
            <ImageOutlined sx={{ color: mediumMain }} />
            <Typography
              color={mediumMain}
              sx={{ "&:hover": { cursor: "pointer", color: medium } }}
            >
              Image
            </Typography>
          </FlexBetween>
          {isNonMobileScreens ? (
            <>
              <FlexBetween gap="0.25rem" onClick={() => setIsSdg(!isSdg)}>
                <GifBoxOutlined sx={{ color: mediumMain }} />
                <Typography color={mediumMain}>select Sdg</Typography>
              </FlexBetween>

              <FlexBetween gap="0.25rem">
                <AttachFileOutlined sx={{ color: mediumMain }} />
                <Typography color={mediumMain}>Attachment</Typography>
              </FlexBetween>

              <FlexBetween gap="0.25rem">
                <MicOutlined sx={{ color: mediumMain }} />
                <Typography color={mediumMain}>Audio</Typography>
              </FlexBetween>
            </>
          ) : (
            <FlexBetween gap="0.25rem">
              <MoreHorizOutlined
                sx={{ color: mediumMain, cursor: "pointer" }}
                onClick={handleClick}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <FlexBetween gap="0.25rem" onClick={() => setIsSdg(!isSdg)}>
                    <GifBoxOutlined sx={{ color: mediumMain }} />
                    <Typography color={mediumMain}>select Sdg</Typography>
                  </FlexBetween>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {" "}
                  <FlexBetween gap="0.25rem">
                    <AttachFileOutlined sx={{ color: mediumMain }} />
                    <Typography color={mediumMain}>Attachment</Typography>
                  </FlexBetween>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {" "}
                  <FlexBetween gap="0.25rem">
                    <MicOutlined sx={{ color: mediumMain }} />
                    <Typography color={mediumMain}>Audio</Typography>
                  </FlexBetween>
                </MenuItem>
              </Menu>
            </FlexBetween>
          )}

          <Button
            disabled={!post}
            onClick={handlePost}
            sx={{
              color: palette.background.alt,
              backgroundColor: palette.primary.main,
              borderRadius: "3rem",
            }}
          >
            POST
          </Button>
        </FlexBetween>
      </WidgetWrapper>
    </>
  );
};

export default MyPostWidget;
