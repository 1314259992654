import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const data = [
  { id: 0, value: 112, label: "National" },
  { id: 1, value: 28, label: "State" },
  { id: 2, value: 75, label: "District" },
];
const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

const getArcLabel = (params) => {
  const percent = params.value / TOTAL;
  return `${(percent * 100).toFixed(0)}%`;
};


export default function PieActiveArc() {
  return (
    <PieChart
      series={[
        {
          data,
          highlightScope: { faded: "global", highlighted: "item" },
          faded: { innerRadius: 30, additionalRadius: -30, color: "gray" },
          arcLabel: getArcLabel,
        },
      ]}
      height={200}
    />
  );
}
// import * as React from "react";
// import { BarChart } from "@mui/x-charts/BarChart";

// const uData = [4000, 3000, 2000];
// const uDat = [4000, 3000, 2000 ];
// const pData = [2400, 1398, 9800];
// const pDat = [2400, 1398, 9800 ];
// const xLabels = [
//   "India",
//   "Page B",
//   "Page C",
 
// ];

// export default function StackedBarChart() {
//   return (
//     <BarChart
//       width={400}
//       height={300}
//       series={[
//         { data: pData, label: "National", id: "pvId", stack: "total" },
//         { data: pDat, label: "pv", id: "pvI", stack: "total" },
//         { data: uData, label: "uv", id: "uvId", stack: "total" },
//         // { data: uDat, label: "uv", id: "uvI", stack: "total" },
//       ]}
//       xAxis={[{ data: xLabels, scaleType: "band" }]}
//     />
//   );
// }
