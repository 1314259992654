import React from "react";
import { useSelector } from "react-redux";
import UserImage from "./UserImage";
import FlexBetween from "./FlexBetween";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
const SearchResults = () => {
  const searchResults = useSelector((state) => state.searchResults); // Accessing searchResults from Redux store
  const navigate = useNavigate();
  // const user = useSelector((state) => state.user);
  const handleClose = (userId, event) => {
    event.preventDefault(); // Prevent the default behavior of the anchor tag
    event.stopPropagation(); // Stop event propagation to prevent triggering onBlur
    navigate(`/profile/${userId}`);
  };
  return (
    <div className="results">
      {searchResults.map((user, id) => (
        // <a href={`/profile/${user.userId}`} key={id}>
        <div key={id} className="user">
          <div className="user-info">
            <a
              href={`/profile/${user._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={(event) => handleClose(user._id, event)} // Pass the event object
            >
              <FlexBetween
                style={{
                  justifyContent: "center",
                  gap: "20px",
                  marginBottom: "20px",
                }}
              >
                <UserImage image={user.picturePath} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  <h4
                    style={{ margin: "0" }}
                  >{`${user.firstName} ${user.lastName}`}</h4>
                  <p style={{ margin: "0" }}>
                    {/* {`${user.location}, ${user.impressions}`} */}
                    {user.userType}
                  </p>
                </div>
              </FlexBetween>
            </a>
          </div>
        </div>
        // </a>
      ))}
    </div>
  );
};

export default SearchResults;
