import {
  ChatBubbleOutlineOutlined,
  ContentCopyOutlined,
  DeleteOutline,
  EditOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  GifBoxOutlined,
  GradingOutlined,
  MoreHorizOutlined,
  ShareOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
// import { io } from "socket.io-client";
import { setPost } from "state";
import { deletePostAction } from "state";
import { io } from "socket.io-client";
import { setSocket } from "state";
import CommentSection from "components/CommentSection";
import { host } from "host";
import "./post.css";
import MyPostWidget from "./MyPostWidget";
import PostUpdateWiget from "./PostUpdateWiget";
import {
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";
const PostWidget = ({
  postId,
  postUserId,
  name,
  description,
  location,
  picturePath,
  userPicturePath,
  likes,
  comments,
  userType,
  sdgs,
  createdAt,
  title,
  summary,
}) => {
  const { userId } = useParams();
  const { _id } = useSelector((state) => state.user);
  // const socket = io("http://localhost:3001"); // Initialize Socket.io
  const socket = useRef();

  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = Boolean(likes[loggedInUserId]);
  const likeCount = Object.keys(likes).length;
  const [isSdg, setIsSdg] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const current = io(`${host}`);
    socket.current = current;
    // dispatch(setSocket({ isConnected: true })); // Dispatch serializable data instead of the entire socket instance
  }, [dispatch]);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;

  const handleShareClick = () => {
    const postUrl = `/post/${postId}`;
    navigator.clipboard
      .writeText(window.location.origin + postUrl)
      .then(() => {
        alert("Link copied to clipboard!");
      })
      .catch((error) => {
        alert("Failed to copy the link.");
        console.error("Error copying link to clipboard: ", error);
      });
  };

  const patchLike = async () => {
    try {
      const response = await fetch(`${host}/posts/${postId}/like`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId }),
      });

      if (!response.ok) {
        throw new Error(
          "Failed to like the post. Server responded with " +
            response.status +
            " " +
            response.statusText
        );
      }

      const updatedPost = await response.json();

      socket.current.emit("like_patched", updatedPost, loggedInUserId);
      dispatch(setPost({ post: updatedPost }));
      console.log("Post liked successfully.");
    } catch (error) {
      console.error("Error liking the post:", error.message);
      // Handle the error appropriately, such as displaying a message to the user or retrying the request.
    }
  };
  const deletePost = async () => {
    if (window.confirm("Do you want to delete this post?")) {
      try {
        const response = await fetch(`${host}/posts/${postId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to delete post");
        }
        dispatch(deletePostAction(postId)); // Pass postId directly as payload

        socket.current.emit("delete_post", postId); // Emit delete_post event to server
      } catch (error) {
        console.error("Delete post error:", error.message);
        // Handle error
      }
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      // Make a DELETE request to your server with the comment ID
      const response = await fetch(
        `${host}/posts/${postId}/comments/${commentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error("Failed to delete post");
      }
      // Optionally, update the UI to reflect the deletion of the comment
      socket.current.emit("delete_comment", data); // Emit delete_post event to server
    } catch (error) {
      console.error("Error deleting comment:", error);
      // Handle error (e.g., display an error message to the user)
    }
  };

  useEffect(() => {
    // Listen for like events
    socket.current.on("post_updated", (updatedPost) => {
      console.log("ye chlanfjad");
      dispatch(setPost({ post: updatedPost }));
    });
    // Listen for comment events
    socket.current.on("comment_created", (updatedPost) => {
      dispatch(setPost({ post: updatedPost }));
    });
    socket.current.on("delete_comment", (updatedPost) => {
      dispatch(setPost({ post: updatedPost }));
    });

    // Listen for delete post events
    socket.current.on("delete_post", (deletedPostId) => {
      if (deletedPostId === postId) {
        dispatch(deletePostAction(deletedPostId));
      }
    });
  }, [postId, dispatch]);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  // Handle delete post event from server
  const modalOpen = () => {
    setUpdateModalOpen(true);
    console.log("fshsbjhgbqa");
  };
  const modalClose = () => {
    setUpdateModalOpen(false);
  };

  const getTimeAgo = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const minutesDiff = differenceInMinutes(currentDate, createdDate);
    if (minutesDiff < 60) {
      return `${minutesDiff} m${minutesDiff !== 1 ? "" : ""}`;
    }
    const hoursDiff = differenceInHours(currentDate, createdDate);
    if (hoursDiff < 24) {
      return `${hoursDiff} h${hoursDiff !== 1 ? "" : ""}`;
    }
    const daysDiff = differenceInDays(currentDate, createdDate);
    return `${daysDiff} day${daysDiff !== 1 ? "s" : ""}`;
  };

  const isCurrentUser = _id === userId;

   const truncatedSummary = truncateText(summary, 40);
   const truncatedTitle = truncateTitle(title, 10);
function truncateText(text, wordLimit) {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
}
function truncateTitle(text, wordLimit) {
  const words = text.split(" ");
  if (words.length > wordLimit) {
    return words.slice(0, wordLimit).join(" ") + "...";
  }
  return text;
}

  return (
    <WidgetWrapper m="2rem 0">
      {updateModalOpen && (
        <>
          <PostUpdateWiget
            postId={postId}
            summarys={summary}
            title={title}
            description={description}
            picturePath={userPicturePath}
          />
          <Divider style={{ marginBottom: "40px" }} />
        </>
      )}
      {isCurrentUser && (
        <IconButton
          className="btn"
          onClick={updateModalOpen ? modalClose : modalOpen}
        >
          {updateModalOpen ? (
            <>
              <EditOutlined /> Hide Update Post
            </>
          ) : (
            <>
              <EditOutlined /> Show Update Post
            </>
          )}
        </IconButton>
      )}
      {/* <Modal open={updateModalOpen} onClose={modalClose}> */}

      {/* </Modal> */}

      {isCurrentUser && (
        <IconButton className="btn" onClick={deletePost}>
          <DeleteOutline /> Delete Post
        </IconButton>
      )}
      <Friend
        friendId={postUserId}
        sdg={sdgs}
        name={name}
        subtitle={userType}
        userPicturePath={userPicturePath}
        createdAt={createdAt}
      />

      {/* <FlexBetween mt="0rem">
        <FlexBetween gap="2rem">
          <h1>{title}</h1>
          <Typography color={main} sx={{ mt: "1rem" }}>
           {description}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </FlexBetween>
      </FlexBetween>

      {picturePath && (
        <img
          width="100%"
          height="auto"
          alt="post"
          style={{ borderRadius: "0.75rem", marginTop: "0.75rem" }}
          src={`${host}/assets/${picturePath}`}
        />
      )} */}

      <div className="post">
        <div className="image">
          <Link to={`/post/${postId}`}>
            {picturePath && (
              <img
                width="100%"
                height="auto"
                alt="post"
                src={`${picturePath}`}
              />
            )}
          </Link>
        </div>
        <div className="texts">
          <Link to={`/post/${postId}`}>
            <h2>{truncatedTitle}</h2>
          </Link>
          {/* <p dangerouslySetInnerHTML={{ __html: description }} /> */}
          {truncatedSummary}
          <br />
        <Link to={`/post/${postId}`}>
        <Button>Read More</Button>
        </Link>
        </div>
      </div>

      <FlexBetween mt="0.25rem">
        <FlexBetween gap="1rem">
          <FlexBetween gap="0.3rem">
            <Typography marginTop="-45px" fontSize="10px">
              Cited by
            </Typography>{" "}
            {/* Moved cited By here */}
            <br />
            <IconButton onClick={patchLike} style={{ marginLeft: "-60px" }}>
              {isLiked ? (
                <GradingOutlined sx={{ color: primary }} />
              ) : (
                <GradingOutlined />
              )}
            </IconButton>
            <Typography>{likeCount}</Typography>
          </FlexBetween>
          <FlexBetween gap="0.3rem">
            <IconButton onClick={() => setIsCommentsOpen(true)}>
              <ChatBubbleOutlineOutlined />
            </IconButton>
            <Typography>{comments.length}</Typography>
          </FlexBetween>
        </FlexBetween>

        <FlexBetween gap="0.25rem">
          <IconButton onClick={handleClick}>
            <ShareOutlined />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <FlexBetween gap="0.25rem" onClick={() => setIsSdg(!isSdg)}>
                <ContentCopyOutlined sx={{ color: mediumMain }} />
                <Typography onClick={handleShareClick} color={mediumMain}>
                  Copy Link
                </Typography>
              </FlexBetween>
            </MenuItem>
          </Menu>
        </FlexBetween>

        {/* <IconButton onClick={handleShareClick}>
          <ShareOutlined />
        </IconButton> */}
      </FlexBetween>

      <Modal open={isCommentsOpen} onClose={() => setIsCommentsOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            width: 1000,
            height: 500,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 0,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <FlexBetween height="100%">
            {/* Image */}
            <Box width="50%" lineHeight="0" height="100%">
              <img
                style={{ width: "100%", height: "100%" }}
                alt="post"
                src={picturePath}
              />
            </Box>

            {/* Comments */}
            <Box width="50%" lineHeight="0" overflow="scroll" padding="1rem">
              <FlexBetween flexDirection="column" height="100%">
                <Typography variant="h6" component="h2">
                  Comments
                </Typography>
                <Box mt="0.5rem" overflow="hidden" flexGrow={1}>
                  {comments.map((comment, i) => (
                    <Box key={`${comment.userId}-${i}`} mb="0.5rem">
                      <Divider />
                      <Box
                        display="flex"
                        alignItems="center"
                        mt="0.5rem"
                        mb="0.5rem"
                      >
                        <img
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            marginRight: "1rem",
                          }}
                          alt="user"
                          src={`${comment.userPicturePath}`}
                        />
                        <Box style={{ overflow: "hidden" }}>
                          <Typography fontWeight="bold">
                            @{comment.username}
                          </Typography>
                          <Typography
                            style={{ width: "50%", wordWrap: "break-word" }}
                          >
                            {comment.content}
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            marginLeft: "40px",
                            marginTop: "-15px",
                          }}
                        >
                          <Typography>
                            {getTimeAgo(comment.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                      {comment.userId === _id && (
                        <Button
                          onClick={() => handleDeleteComment(comment._id)}
                        >
                          Delete
                        </Button>
                      )}
                    </Box>
                  ))}
                  <Divider />
                </Box>
                <CommentSection postId={postId} />
              </FlexBetween>
            </Box>
          </FlexBetween>
        </Box>
      </Modal>
    </WidgetWrapper>
  );
};

export default PostWidget;
