// import { EditOutlined } from "@mui/icons-material";
// import { Button, Divider, Input, MenuItem } from "@mui/material";
// import React, { useRef, useState } from "react";

// import { useSelector } from "react-redux";

// const MyProfile = () => {
//   const user = useSelector((state) => state.user);
//   const fileInputRef = useRef(null);
//   const [profilePicture, setProfilePicture] = useState(user.picturePath);

//   const handleClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setProfilePicture(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const name = user.firstName + " " + user.lastName;

//   console.log(name);
//   return (
//     <div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <input
//           type="file"
//           accept="image/*"
//           style={{ display: "none" }}
//           ref={fileInputRef}
//           onChange={handleFileChange}
//         />
//         <div style={{ position: "relative", width: "200px", height: "200px" }}>
//           {/* Overlay */}
//           <div
//             style={{
//               width: "100%",
//               height: "100%",
//               borderRadius: "50%",
//               backgroundColor: "rgba(0,0,0,0.4)",
//               position: "absolute",
//               left: 0,
//               top: 0,
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               opacity: 0,
//               transition: "opacity 0.3s",
//             }}
//             onMouseEnter={(e) => (e.target.style.opacity = 1)}
//             onMouseLeave={(e) => (e.target.style.opacity = 0)}
//             onClick={handleClick}
//           >
//             {/* Edit Icon */}
//             <EditOutlined
//               style={{ zIndex: "2555", color: "#fff", fontSize: "40px" }}
//             />
//           </div>

//           {/* Profile Picture */}
//           <img
//             src={profilePicture}
//             alt=""
//             style={{
//               width: "100%",
//               height: "100%",
//               cursor: "pointer",
//               borderRadius: "50%",
//             }}
//           />
//         </div>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           flexDirection: "column",
//         }}
//       >
//         <div style={{ padding: "20px 0 0 0" }}>
//           {/* <Input value={name} disabled /> */}
//           name : {name}
//         </div>
//         <div style={{ padding: "20px 0 0 0" }}>
//           {/* <Input value={user.email} disabled /> */}
//           Email : {user.email}
//         </div>
//         <div style={{ padding: "20px 0 0 0" }}>
//           password :{/* <Input placeholder="Update your oassword" /> */}
//           <a href="/forgotPassword">
//             <Button>forgot password</Button>
//           </a>
//         </div>
//         <div style={{ padding: "20px 0 0 0" }}>
//           Date of Birth :
//           <Input placeholder="Twitter Profile Link" />
//         </div>
//         <div style={{ padding: "20px 0 0 0" }}>
//           Mobile Number :
//           <Input placeholder="Twitter Profile Link" />
//         </div>
//         <Divider style={{ paddingBottom: "20px" }} />
//         Social Links
//         <div style={{ padding: "20px 0 0 0" }}>
//            LinkedIn profile Link :
//           <Input placeholder="LinkedIn Profile Link" />
//         </div>
//         <div style={{ padding: "20px 0 0 0" }}>
//            X profile Link :
//           <Input placeholder="Twitter Profile Link" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MyProfile;
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EditOutlined } from "@mui/icons-material";
import { Button, Divider, Input } from "@mui/material";
import { host } from "host";

const MyProfile = () => {
  const user = useSelector((state) => state.user);
  const fileInputRef = useRef(null);
  const [profilePicture, setProfilePicture] = useState(user.picturePath);
  const [linkedin, setLinkedin] = useState(user.linkedin || "");
  const [xProfile, setXProfile] = useState(user.X || "");

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    const userId = user._id;
    try {
      const response = await fetch(`${host}/users/${userId}/links`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ linkedin, X: xProfile }),
      });

      if (response.ok) {
        const result = await response.json();
        alert("Profile updated successfully");
        console.log("Updated user data:", result);
      } else {
        const error = await response.json();
        alert("Error updating profile: " + JSON.stringify(error));
      }
    } catch (error) {
      alert("Network error: " + error.message);
    }
  };

  const name = `${user.firstName} ${user.lastName}`;

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileChange}
        />
        <div style={{ position: "relative", width: "200px", height: "200px" }}>
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              backgroundColor: "rgba(0,0,0,0.4)",
              position: "absolute",
              left: 0,
              top: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              opacity: 0,
              transition: "opacity 0.3s",
            }}
            onMouseEnter={(e) => (e.target.style.opacity = 1)}
            onMouseLeave={(e) => (e.target.style.opacity = 0)}
            onClick={handleClick}
          >
            <EditOutlined
              style={{ zIndex: "2555", color: "#fff", fontSize: "40px" }}
            />
          </div>
          <img
            src={profilePicture}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ padding: "20px 0 0 0" }}>name : {name}</div>
        <div style={{ padding: "20px 0 0 0" }}>Email : {user.email}</div>
        <div style={{ padding: "20px 0 0 0" }}>
          password :{" "}
          <a href="/forgotPassword">
            <Button>forgot password</Button>
          </a>
        </div>
        <div style={{ padding: "20px 0 0 0" }}>
          Date of Birth : <Input placeholder="Date of Birth" />
        </div>
        <div style={{ padding: "20px 0 0 0" }}>
          Mobile Number : <Input placeholder="Mobile Number" />
        </div>
        <Divider style={{ paddingBottom: "20px" }} />
        Social Links
        <div style={{ padding: "20px 0 0 0" }}>
          LinkedIn profile Link :
          <Input
            placeholder="LinkedIn Profile Link"
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />
        </div>
        <div style={{ padding: "20px 0 0 0" }}>
          X profile Link :
          <Input
            placeholder="X Profile Link"
            value={xProfile}
            onChange={(e) => setXProfile(e.target.value)}
          />
        </div>
        <div style={{ padding: "20px 0 0 0" }}>
          <Button onClick={handleSave}>Save</Button>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
