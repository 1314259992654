import React, { useState } from "react";
import { InputBase } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setSearchResults } from "state";
import SearchResults from "./SearchResults"; // Import SearchResults component
import { host } from "host";

const SearchBar = () => {
  const token = useSelector((state) => state.token);
  const searchResults = useSelector((state) => state.searchResults); // Access search results from Redux store

  const [input, setInput] = useState("");
  const [isFocused, setIsFocused] = useState(false); // State to track input focus
  const dispatch = useDispatch();

  const fetchUsers = async (value) => {
    if (!value.trim()) {
      dispatch(setSearchResults([])); // Dispatch empty array when input is empty
      return;
    }
    try {
      fetch(`${host}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => response.json())
        .then((json) => {
          const results = json.filter((user) => {
            const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
            return fullName.includes(value.toLowerCase());
          });
          dispatch(setSearchResults(results));
          console.log(results);
        });
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleChange = (value) => {
    setInput(value);
    fetchUsers(value);
  };

  const handleFocus = () => {
    setIsFocused(true); // Update focus state when input is focused
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false); // Update focus state when input is blurred
    }, 250);
  };

  return (
    <div>
      <InputBase
        placeholder="Search..."
        value={input}
        onChange={(e) => handleChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {isFocused && searchResults.length > 0 && <SearchResults />}
      {/* Render SearchResults component when input is focused */}
    </div>
  );
};

export default SearchBar;
