import React, { useEffect, useState } from "react";
import axios from "axios";
// import { useSelector } from "react-redux";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import { host } from "host";

function StateLocation({ userLocation }) {
  // console.log(userLocation);
  const [userState, setUserState] = useState("");
  const [userLong, setUserLong] = useState("");
  const [userLat, SetUserLat] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userCity, setUserCity] = useState("");
  const [districtCount, setDistrictCount] = useState(null);
  const [numberOfState, setNumberOfState] = useState(null);

  // const user = useSelector((state) => state.user);

  async function showPosition(longitude, latitude) {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      const data = await response.json();

      const state = data.address.state;
      const country = data.address.country;
      const city = data.address.city;
      setUserState(state);
      setUserCountry(country);
      setUserCity(city);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function getCoordinates(cityName) {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          cityName
        )}`
      );
      const data = await response.json();

      if (data && data.length > 0) {
        const latitude = data[0].lat;
        const longitude = data[0].lon;
        setUserLong(longitude);
        SetUserLat(latitude);
        // console.log("Latitude:", latitude);
        // console.log("Longitude:", longitude);
      } else {
        console.error("No results found for the city:", cityName);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function getDistrictCount(stateName) {
    try {
      // Replace with the actual API endpoint for district count
      const response = await axios.get(`${host}/users/districts`);
      // Check if the state exists in the response data
      const stateNames = Object.keys(response.data);

      // Get the length of the array (number of states)
      const numberOfStates = stateNames.length;
      setNumberOfState(numberOfStates);
      // console.log(numberOfStates);
      if (stateName in response.data) {
        const districtCou = response.data[stateName];
        console.log(`District count for ${stateName}: ${districtCou}`);
        return setDistrictCount(districtCou);
      }
    } catch (error) {
      console.error("Error fetching district count:", error);
    }
  }
  // console.log(districtCount);

  // Usage example:
  useEffect(() => {
    getCoordinates(userLocation);
    if (userLong && userLat) {
      showPosition(userLong, userLat);
      console.log("ye chla scollf and");
    }
  });

  useEffect(() => {
    if (userState) {
      getDistrictCount(userState);
    }
  }, [userState]);

  // console.log(userLocation);
  return (
    <>
      {/* {userState} */}
      {userState && userCity && userCountry && (
        <>
          {/* {userState} has {districtCount} districts. */}
          <AdvertWidget
            state={userState}
            Country={userCountry}
            name="UN SDG ranking"
            district={districtCount}
            numberOfState={numberOfState}
            city={userCity}
          />
        </>
      )}
    </>
  );
}

export default StateLocation;
