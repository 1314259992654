import {
  ManageAccountsOutlined,
  EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined,
  AccessAlarmOutlined,
  GradingOutlined,
  PersonAddAlt1Outlined,
  ArticleOutlined,
  DateRangeOutlined,
} from "@mui/icons-material";
import { Box, Typography, Divider, useTheme } from "@mui/material";
import UserImage from "components/UserImage";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { host } from "host";
import { format } from "date-fns";

const UserWidget = ({ userId, picturePath }) => {
  const [user, setUser] = useState(null);
  const [blog, setBlog] = useState("");
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const { _id } = useSelector((state) => state.user);
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  const getUser = async () => {
    try {
      const response = await fetch(`${host}/users/${userId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(
          "Failed to fetch user data. Server responded with " +
            response.status +
            " " +
            response.statusText
        );
      }

      const data = await response.json();
      setUser(data);
      console.log("User data fetched successfully.");
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      // Handle the error appropriately, such as displaying a message to the user or retrying the request.
    }
  };
  const sdgsArray = user && user.sdgs ? user.sdgs : [];
  const arrayAsString = sdgsArray.join(", ");
  // console.log(arrayAsString);

  useEffect(() => {
    getUser();
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getUserPosts();
  }, [userId]);
  // const getUserPosts = async () => {
  //   try {
  //     const response = await fetch(`${host}/posts/${userId}/posts`, {
  //       method: "GET",
  //       headers: { Authorization: `Bearer ${token}` },
  //     });

  //     if (!response.ok) {
  //       throw new Error(
  //         "Failed to fetch user posts. Server responded with " +
  //           response.status +
  //           " " +
  //           response.statusText
  //       );
  //     }

  //     const data = await response.json();
  //     setBlog(data.length);
  //     // data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //     // dispatch(setPosts({ posts: data }));
  //     console.log("User posts fetched and set successfully.");
  //   } catch (error) {
  //     console.error("Error fetching user posts:", error.message);
  //     // Handle the error appropriately, such as displaying a message to the user or retrying the request.
  //   }
  // };

  const getUserPosts = async () => {
    try {
      const response = await fetch(`${host}/posts/${userId}/posts`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(
          "Failed to fetch user posts. Server responded with " +
            response.status +
            " " +
            response.statusText
        );
      }

      const posts = await response.json();

      // Calculate total likes
      let totalLikes = 0;
      posts.forEach((post) => {
        // Access the likes object and count the number of keys with value true
        const likes = post.likes;
        const likesCount = Object.values(likes).filter((liked) => liked).length;
        totalLikes += likesCount;
      });

      setBlog(posts.length); // Setting the number of posts
      setLikes(totalLikes);
      console.log("Total likes in all posts:", totalLikes);
      console.log("User posts fetched and set successfully.");
    } catch (error) {
      console.error("Error fetching user posts:", error.message);
      // Handle the error appropriately, such as displaying a message to the user or retrying the request.
    }
  };
  const [likes, setLikes] = useState("");
  console.log(blog);

  if (!user) {
    return null;
  }

  const {
    firstName,
    lastName,
    location,
    occupation,
    viewedProfile,
    impressions,
    friends,
    userType,
  } = user;

  const isCurrentUser = _id === userId;

  return (
    <WidgetWrapper>
      {userType === "CSR" && (
        /* Render content for CSR user */
        <>
          {/* Render content for CSR user */}
          {/* FIRST ROW */}
          <FlexBetween gap="0.5rem" pb="1.1rem">
            <FlexBetween
              gap="1rem"
              onClick={() => navigate(`/profile/${userId}`)}
            >
              <UserImage image={picturePath} />
              <Box>
                <Typography
                  variant="h4"
                  color={dark}
                  fontWeight="500"
                  sx={{
                    "&:hover": {
                      color: palette.primary.light,
                      cursor: "pointer",
                    },
                  }}
                >
                  {firstName} {lastName}
                </Typography>

                {/* <Typography color={medium}>{friends.length} friends</Typography> */}
                <Typography color={medium}>{userType}</Typography>
              </Box>
            </FlexBetween>
            {isCurrentUser && <ManageAccountsOutlined />}
          </FlexBetween>

          <Divider />

          {/* SECOND ROW */}
          {/* <Box p="1rem 0">
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{location}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <AccessAlarmOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {Math.floor(Math.random() * 10)} Years5 Years
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem">
              <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{occupation}</Typography>
            </Box>
          </Box>

          <Divider /> */}
          {/* SECOND ROW */}
          <Box p="1rem 0">
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <DateRangeOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {format(new Date(user.createdAt), "MMMM dd, yyyy")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem">
              <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{occupation}</Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{userType}</Typography>
            </Box> */}
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <ArticleOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>Blogs: {blog}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{location}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <GradingOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {/* {Math.floor(Math.random() * 10)} Years */}
                total citation: {likes}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <PersonAddAlt1Outlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {/* {Math.floor(Math.random() * 10)} Years */}
                {arrayAsString}
              </Typography>
            </Box>
          </Box>
          <Divider />

          {/* Third Row */}
          <Box p="1rem 0">
            {isCurrentUser && (
              <>
                <div style={{ display: "flex", gap: "20px" }}>
                  <p>Where to invest?</p>
                  <p>Whom to invest?</p>
                  <p>How to invest?</p>
                </div>
              </>
            )}
          </Box>
          {/* <Divider /> */}

          {/* FOURTH ROW */}
          <Box p="1rem 0">
            <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
              Social Profiles
            </Typography>

            <FlexBetween gap="1rem" mb="0.5rem">
              <FlexBetween gap="1rem">
                <img src="../assets/twitter.png" alt="twitter" />
                <Box>
                  <Typography color={main} fontWeight="500">
                    Twitter
                  </Typography>
                  <a target="_blank" href={user.X} rel="noopener noreferrer">
                    <Typography color={medium}>{user.X}</Typography>
                  </a>
                </Box>
              </FlexBetween>
              <EditOutlined sx={{ color: main }} />
            </FlexBetween>

            <FlexBetween gap="1rem">
              <FlexBetween gap="1rem">
                <img src="../assets/linkedin.png" alt="linkedin" />
                <Box>
                  <Typography color={main} fontWeight="500">
                    Linkedin
                  </Typography>
                  <a
                    target="_blank"
                    href={user.linkedin}
                    rel="noopener noreferrer"
                  >
                    <Typography color={medium}>{user.linkedin}</Typography>
                  </a>
                </Box>
              </FlexBetween>
              <EditOutlined sx={{ color: main }} />
            </FlexBetween>
          </Box>
        </>
      )}

      {userType === "NGO" && (
        /* Render content for NGO user */
        <>
          {/* FIRST ROW */}
          <FlexBetween gap="0.5rem" pb="1.1rem">
            <FlexBetween
              gap="1rem"
              onClick={() => navigate(`/profile/${userId}`)}
            >
              <UserImage image={picturePath} />
              <Box>
                <Typography
                  variant="h4"
                  color={dark}
                  fontWeight="500"
                  sx={{
                    "&:hover": {
                      color: palette.primary.light,
                      cursor: "pointer",
                    },
                  }}
                >
                  {firstName} {lastName}
                </Typography>
                {/* <Typography color={medium}>{friends.length} friends</Typography> */}
                <Typography color={medium}>{userType}</Typography>
              </Box>
            </FlexBetween>
            {isCurrentUser && <ManageAccountsOutlined />}
          </FlexBetween>

          <Divider />

          {/* SECOND ROW */}
          {/* <Box p="1rem 0">
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{location}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <AccessAlarmOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {Math.floor(Math.random() * 10)} Years5 Years
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem">
              <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{occupation}</Typography>
            </Box>
          </Box>

          <Divider /> */}
          {/* SECOND ROW */}
          <Box p="1rem 0">
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <DateRangeOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {format(new Date(user.createdAt), "MMMM dd, yyyy")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem">
              <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{occupation}</Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{userType}</Typography>
            </Box> */}
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <ArticleOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>Blogs: {blog}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{location}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <GradingOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {/* {Math.floor(Math.random() * 10)} Years */}
                total citation: {likes}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <PersonAddAlt1Outlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {/* {Math.floor(Math.random() * 10)} Years */}
                {arrayAsString}
              </Typography>
            </Box>
          </Box>
          <Divider />

          {/* THIRD ROW */}
          <Box p="1rem 0">
            <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
              Impact Keyword
            </Typography>
            {/* <FlexBetween mb="0.5rem">
          <Typography color={medium}>Who's viewed your profile</Typography>
          <Typography color={main} fontWeight="500">
            {viewedProfile}
          </Typography>
        </FlexBetween> */}

            <FlexBetween mb="0.5rem">
              <Typography color={medium}>Impact status</Typography>
              <Typography color={main} fontWeight="500">
                {/* {Math.floor(Math.random() * 10000)} */}
                Excellent
              </Typography>
            </FlexBetween>
            <FlexBetween mb="0.5rem">
              <Typography color={medium}>Q-factor</Typography>
              <Typography color={main} fontWeight="500">
                {/* {Math.floor(Math.random() * 10000)} */}
                10/10
              </Typography>
            </FlexBetween>
            <FlexBetween>
              <Typography color={medium}>Portfolio SDGs </Typography>
              <Typography color={main} fontWeight="500">
                {/* {Math.floor(Math.random() * 10000)} */}
                {sdgsArray.length}/17
              </Typography>
            </FlexBetween>
            <FlexBetween>
              <Typography color={medium}>IAA</Typography>
              <Typography color={main} fontWeight="500">
                {/* {Math.floor(Math.random() * 10000)} */}
                Positive
              </Typography>
            </FlexBetween>
          </Box>

          <Divider />

          {/* FOURTH ROW */}
          <Box p="1rem 0">
            <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
              Social Profiles
            </Typography>

            <FlexBetween gap="1rem" mb="0.5rem">
              <FlexBetween gap="1rem">
                <img src="../assets/twitter.png" alt="twitter" />
                <Box>
                  <Typography color={main} fontWeight="500">
                    Twitter
                  </Typography>
                  <a target="_blank" href={user.X} rel="noopener noreferrer">
                    <Typography color={medium}>{user.X}</Typography>
                  </a>
                </Box>
              </FlexBetween>
              <EditOutlined sx={{ color: main }} />
            </FlexBetween>

            <FlexBetween gap="1rem">
              <FlexBetween gap="1rem">
                <img src="../assets/linkedin.png" alt="linkedin" />
                <Box>
                  <Typography color={main} fontWeight="500">
                    Linkedin
                  </Typography>
                  <a
                    target="_blank"
                    href={user.linkedin}
                    rel="noopener noreferrer"
                  >
                    <Typography color={medium}>{user.linkedin}</Typography>
                  </a>
                </Box>
              </FlexBetween>
              <EditOutlined sx={{ color: main }} />
            </FlexBetween>
          </Box>
        </>
      )}

      {userType === "SocialUser" && (
        /* Render content for SocialUser user */
        <>
          {" "}
          {/* FIRST ROW */}
          <FlexBetween gap="0.5rem" pb="1.1rem">
            <FlexBetween
              gap="1rem"
              onClick={() => navigate(`/profile/${userId}`)}
            >
              <UserImage image={picturePath} />
              <Box>
                <Typography
                  variant="h4"
                  color={dark}
                  fontWeight="500"
                  sx={{
                    "&:hover": {
                      color: palette.primary.light,
                      cursor: "pointer",
                    },
                  }}
                >
                  {firstName} {lastName}
                </Typography>
                {/* <Typography color={medium}>{friends.length} friends</Typography> */}
                <Typography color={medium}>{userType}</Typography>
              </Box>
            </FlexBetween>
            {isCurrentUser && (
              <>
                <Link to="/myProfile">
                  <ManageAccountsOutlined />
                </Link>
              </>
            )}
          </FlexBetween>
          <Divider />
          {/* SECOND ROW */}
          <Box p="1rem 0">
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <DateRangeOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {format(new Date(user.createdAt), "MMMM dd, yyyy")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem">
              <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{occupation}</Typography>
            </Box>
            {/* <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{userType}</Typography>
            </Box> */}
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <ArticleOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>Blogs: {blog}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <LocationOnOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>{location}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <GradingOutlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {/* {Math.floor(Math.random() * 10)} Years */}
                total citation: {likes}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
              <PersonAddAlt1Outlined fontSize="large" sx={{ color: main }} />
              <Typography color={medium}>
                {/* {Math.floor(Math.random() * 10)} Years */}
                {arrayAsString}
              </Typography>
            </Box>
          </Box>
          <Divider />
          {/* THIRD ROW */}
          {/* <Box p="1rem 0">
            <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
              Impact Keyword
            </Typography> */}
          {/* <FlexBetween mb="0.5rem">
          <Typography color={medium}>Who's viewed your profile</Typography>
          <Typography color={main} fontWeight="500">
            {viewedProfile}
          </Typography>
        </FlexBetween> */}
          {/* <FlexBetween mb="0.5rem">
              <Typography color={medium}>Impact status</Typography>
              <Typography color={main} fontWeight="500">
                {Math.floor(Math.random() * 10000)}
              </Typography>
            </FlexBetween>
            <FlexBetween mb="0.5rem">
              <Typography color={medium}>Q-factor</Typography>
              <Typography color={main} fontWeight="500">
                {Math.floor(Math.random() * 10000)}
              </Typography>
            </FlexBetween>
            <FlexBetween>
              <Typography color={medium}>Portfolio SDGs </Typography>
              <Typography color={main} fontWeight="500">
                {Math.floor(Math.random() * 10000)}
              </Typography>
            </FlexBetween>
            <FlexBetween>
              <Typography color={medium}>IAA</Typography>
              <Typography color={main} fontWeight="500">
                {Math.floor(Math.random() * 10000)}
              </Typography>
            </FlexBetween>
          </Box>
          <Divider /> */}
          {/* FOURTH ROW */}
          <Box p="1rem 0">
            <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
              Social Profiles
            </Typography>

            <FlexBetween gap="1rem" mb="0.5rem">
              <FlexBetween gap="1rem">
                <img src="../assets/twitter.png" alt="twitter" />
                <Box>
                  <Typography color={main} fontWeight="500">
                    Twitter
                  </Typography>
                  <a target="_blank" href={user.X} rel="noopener noreferrer">
                    <Typography color={medium}>{user.X}</Typography>
                  </a>
                </Box>
              </FlexBetween>
              <EditOutlined sx={{ color: main }} />
            </FlexBetween>

            <FlexBetween gap="1rem">
              <FlexBetween gap="1rem">
                <img src="../assets/linkedin.png" alt="linkedin" />
                <Box>
                  <Typography color={main} fontWeight="500">
                    Linkedin
                  </Typography>
                  <a
                    target="_blank"
                    href={user.linkedin}
                    rel="noopener noreferrer"
                  >
                    <Typography color={medium}>{user.linkedin}</Typography>
                  </a>
                </Box>
              </FlexBetween>
              <EditOutlined sx={{ color: main }} />
            </FlexBetween>
          </Box>
        </>
      )}
    </WidgetWrapper>
  );
};

export default UserWidget;
