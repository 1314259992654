import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import PostPage from "scenes/postPage";
// import Filter from "scenes/filter";
import SDGSelectionPage from "scenes/selectionPage";
import MyProfile from "scenes/myProfilePage";
import ForgotPassword from "scenes/forgotPassword/forgotPassword";
import ResetPassword from "scenes/resetPassword/resetPassword";
function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            {/* <Route path="/filter" element={<Filter />} /> */}
            <Route
              path="/home"
              element={isAuth ? <HomePage /> : <Navigate to="/" />}
            />
            <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
            />
            <Route
              path="/sdgSelectionPage"
              element={isAuth ? <SDGSelectionPage /> : <Navigate to="/" />}
            />
            <Route
              path="/myProfile"
              element={isAuth ? <MyProfile /> : <Navigate to="/" />}
            />
            <Route
              path="/post/:postId"
              element={isAuth ? <PostPage /> : <Navigate to="/" />}
            />
            <Route
              path="/forgotPassword"
              element={isAuth ? <ForgotPassword /> : <Navigate to="/" />}
            />
            <Route
              path="/reset_password/:id/:token"
              element={isAuth ? <ResetPassword /> : <Navigate to="/" />}
            />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
