import { host } from "host";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setSinglePost } from "state";
import styles from "../widgets/post.module.css";
import CommentSection from "components/CommentSection";
import { Box, Divider, Typography } from "@mui/material";
import {
  format,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
} from "date-fns";
import { io } from "socket.io-client";

const PostPage = () => {
  const dispatch = useDispatch();
  const { postId } = useParams();
  const [post, setPost] = useState({});
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.token);
  const socket = useRef();
  useEffect(() => {
    const current = io(`${host}`);
    socket.current = current;
    // dispatch(setSocket({ isConnected: true })); // Dispatch serializable data instead of the entire socket instance
  }, [dispatch]);
  const getSinglePost = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${host}/posts/${postId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setPost(data);
      setComments(data.comments || []);
      dispatch(setSinglePost({ singlePost: data }));
    } catch (error) {
      console.error("Error fetching post:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSinglePost();
  }, [postId]);

  useEffect(() => {
    const current = socket.current;

    current.on("comment_created", (updatedPost) => {
      if (updatedPost._id === postId) {
        setComments(updatedPost.comments);
      }
    });

    current.on("comment_deleted", (updatedPost) => {
      if (updatedPost._id === postId) {
        setComments(updatedPost.comments);
      }
    });
  });
  const getTimeAgo = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const minutesDiff = differenceInMinutes(currentDate, createdDate);
    if (minutesDiff < 60) {
      return `${minutesDiff} m${minutesDiff !== 1 ? "" : ""} ago`;
    }
    const hoursDiff = differenceInHours(currentDate, createdDate);
    if (hoursDiff < 24) {
      return `${hoursDiff} h${hoursDiff !== 1 ? "" : ""} ago`;
    }
    const daysDiff = differenceInDays(currentDate, createdDate);
    return `${daysDiff} day${daysDiff !== 1 ? "s" : ""} ago`;
  };

  // console.log(comments);
  // useEffect(() => {
  //   getSinglePosts();
  // }, []);

  if (loading) {
    return (
      <div className={styles.page}>
        <div className={styles.loader}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.postPage}>
      <div className={styles.postHeader}>
        <img
          className={styles.authorImg}
          src={`${post.userPicturePath}`}
          alt=""
        />
        <div className={styles.authorInfo}>
          <h3>By: {post.firstName}</h3>
          <Typography>
          {getTimeAgo(post.createdAt)}
          </Typography>
          {/* <p>{new Date(post.createdAt).toLocaleString()}</p> */}
        </div>
      </div>
      <h1 className={styles.postTitle}>{post.title}</h1>
      <img className={styles.postImage} src={`${post.picturePath}`} alt="" />
      <div className={styles.postContent}>
        <p>{post.summary}</p>
        <p dangerouslySetInnerHTML={{ __html: post.description }} />
      </div>
      <div className={styles.commentsSection}>
        <h2>Comments</h2>
        {/* <form onSubmit={handleCommentSubmit} className={styles.commentForm}>
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            className={styles.commentInput}
          />
          <button type="submit" className={styles.commentButton}>
            Submit
          </button>
        </form> */}
        <CommentSection postId={postId} />
        <div className={styles.commentsList}>
          {comments.map((comment) => (
            <div key={comment._id} className={styles.comment}>
              {/* <Box>
                <FlexBetween style={{ flexDirection: "column" }}>
                  <UserImage image={comment.userPicturePath} />
                  <p>{comment.username}</p>
                  <Typography>{getTimeAgo(comment.createdAt)}</Typography>
                </FlexBetween>
              </Box> */}
              <Box mt="0.5rem" overflow="hidden" flexGrow={1}>
                <Box key={`${comment.userId}`} mb="0.5rem">
                  <Divider />
                  <Box
                    display="flex"
                    alignItems="center"
                    mt="0.5rem"
                    mb="0.5rem"
                  >
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        marginRight: "1rem",
                      }}
                      alt="user"
                      src={`${comment.userPicturePath}`}
                    />
                    <Box style={{ overflow: "hidden" }}>
                      <Typography fontWeight="bold">
                        @{comment.username}
                      </Typography>
                      <Typography
                        style={{ width: "50%", wordWrap: "break-word" }}
                      >
                        {comment.content}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        marginLeft: "40px",
                        marginTop: "-15px",
                      }}
                    >
                      <Typography>{getTimeAgo(comment.createdAt)}</Typography>
                    </Box>
                  </Box>
                  {/* {comment.userId === _id && (
                      <Button onClick={() => handleDeleteComment(comment._id)}>
                        Delete
                      </Button>
                    )} */}
                </Box>

                <Divider />
              </Box>
              {/* <small>{comment.content} on </small> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PostPage;
