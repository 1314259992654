// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAwjm9Ef4mltfJc0H3w0VJoy7PyvwFNMlY",
  authDomain: "social-storage-d8947.firebaseapp.com",
  projectId: "social-storage-d8947",
  storageBucket: "social-storage-d8947.appspot.com",
  messagingSenderId: "169458938758",
  appId: "1:169458938758:web:f2944f2d87bea4927b3207",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;