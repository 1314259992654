import { Typography, useTheme } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import PieAnimation from "components/PiChart";
import WidgetWrapper from "components/WidgetWrapper";
import "./post.css";
const AdvertWidget = ({
  name,
  state,
  district,
  Country,
  city,
  numberOfState,
}) => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  return (
    <WidgetWrapper>
      {name === "UN SDG ranking" && (
        <>
          <div>
            <FlexBetween>
              <Typography color={dark} variant="h5" fontWeight="500">
                {name}
              </Typography>

              {/* <Typography color={medium}>Create Ad</Typography> */}
            </FlexBetween>
            {/* <PieAnimation /> */}
            <Typography
              color={dark}
              textAlign="center"
              mt="20px"
              p="10px"
              variant="h5"
              fontWeight="500"
            >
              <ul style={{ listStyle: "none" }}>
                <li
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="color">National</span>{" "}
                  <span className="state">{Country}</span>{" "}
                  <span className="state">112/166.</span>
                </li>
                <li
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="color">State/UT</span>{" "}
                  <span className="state">{state}</span>{" "}
                  <span className="state">7/{numberOfState}</span>
                </li>
                <li
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span className="color">District</span>{" "}
                  <span className="state">{city}</span>{" "}
                  <span className="state">16/{district}</span>
                </li>
              </ul>
            </Typography>
            {/* <FlexBetween>
            <Typography color={main}>MikaCosmetics</Typography>
            <Typography color={medium}>mikacosmetics.com</Typography>
          </FlexBetween> */}
            {/* <Typography color={medium} m="0.5rem 0">
            Your pathway to stunning and immaculate beauty and made sure your
            skin is exfoliating skin and shining like light.
          </Typography> */}
          </div>
        </>
      )}
    </WidgetWrapper>
  );
};

export default AdvertWidget;
