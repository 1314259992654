import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Divider } from "@mui/material";
import axios from "axios"; // Import Axios for making HTTP requests
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { host } from "host";

const CommentSection = ({ postId, comments }) => {
  const [newComment, setNewComment] = useState("");
  const { _id, firstName, lastName, picturePath } = useSelector(
    (state) => state.user
  );
  const token = useSelector((state) => state.token);
  const socket = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const current = io(`${host}`);
    socket.current = current;
    // dispatch(setSocket({ isConnected: true })); // Dispatch serializable data instead of the entire socket instance
    console.log(socket.current);
  }, [dispatch]);

  console.log(picturePath);

  const handleCommentSubmit = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const commentData = {
        userId: _id,
        username: `${firstName} ${lastName}`,
        userPicturePath: picturePath,
        content: newComment,
      };

      // Make a POST request to your server with the comment data and token
      const newCommentResponse = await axios.post(
        `${host}/posts/${postId}/comments`,
        commentData,
        config
      );

      const Comment = newCommentResponse.data; // Retrieve the newly created comment data

      socket.current.emit("comment_created", Comment);
      setNewComment("");

      // Optionally, you can fetch updated comments from the server and update the UI
    } catch (error) {
      console.error("Error posting comment:", error);
      // Handle error (e.g., display an error message to the user)
    }
  };

  return (
    <Box mt="1rem">
      {/* Text input for entering a new comment */}
      <input
        type="text"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
      />
      {/* Button to submit the comment */}
      <button onClick={handleCommentSubmit}>Post Comment</button>
    </Box>
  );
};

export default CommentSection;
