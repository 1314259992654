import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import TopBar from "components/TopBar";
import Search from "components/Search";
import { useState } from "react";
import SearchResults from "components/SearchResults";
import StateLocation from "components/StateLocation";
// import Countrystatecity from "components/State";
// import CountryStateCitySelector from "components/State";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath, location } = useSelector((state) => state.user);
  // console.log(location);
  return (
    <Box>
      <Navbar />
      {/* topbar component */}
      {/* <SearchResults /> */}
      <TopBar />
      <Box
        width="100%"
        padding="2rem 6%"
        paddingTop="0"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
      >
        <Box flexBasis={isNonMobileScreens ? "35%" : undefined}>
          <Box flexBasis="26%" mt="10px">
            <UserWidget userId={_id} picturePath={picturePath} />

            {isNonMobileScreens && (
              <Box flexBasis="26%" mt="10px">
                {/* <AdvertWidget name="UN SDG ranking" /> */}
                <StateLocation userLocation={location} />

                <Box m="2rem 0" />
                {/* <FriendListWidget userId={_id} /> */}
                {/* <CountryStateCitySelector /> */}
              </Box>
            )}
            {/* {isNonMobileScreens && (
            <Box flexBasis="26%" mt="90px">
            <AdvertWidget name="Sponser" />
            <Box m="2rem 0" />
            <FriendListWidget userId={_id} />
            </Box>
          )} */}
          </Box>
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "60%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <MyPostWidget picturePath={picturePath} />
          <PostsWidget userId={_id} />
        </Box>
        {/* <Box flexBasis="26%" mt="90px">
          <AdvertWidget />
          <Box m="2rem 0" />
          <FriendListWidget userId={_id} />
        </Box> */}
      </Box>
    </Box>
  );
};

export default HomePage;
