import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts, setSelectedSdgs } from "state";
import PostWidget from "./PostWidget";
import { io } from "socket.io-client";
import { setSocket } from "state";
import { host } from "host";
// import { io } from "socket.io-client";

const PostsWidget = ({ userId, isProfile = false, blog }) => {
  // const socket = io("http://localhost:3001");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(null);

  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const socket = useRef(null);
  const isSelectedSdgs = useSelector((state) => state.selectedSdgs);
  useEffect(() => {
    const current = io(`${host}`);
    socket.current = current;
    dispatch(setSocket({ isConnected: true })); // Dispatch serializable data instead of the entire socket instance
  }, [dispatch]);

  const handelInfiniteScroll = async () => {
    console.log("scrollHeight" + document.documentElement.scrollHeight);
    // console.log("innerHeight" + window.innerHeight);
    // console.log("scrollTop" + document.documentElement.scrollTop);
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setLoading(true);
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handelInfiniteScroll);
    return () => window.removeEventListener("scroll", handelInfiniteScroll);
  }, []);

  const getPosts = async () => {
    try {
      const response = await fetch(`${host}/posts?_limit=5&_page=${page}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(
          "Failed to fetch posts. Server responded with " +
            response.status +
            " " +
            response.statusText
        );
      }

      const data = await response.json();
      if (data.length === 0) {
        // If the response is an empty array, there are no more posts to load
        window.removeEventListener("scroll", handelInfiniteScroll);
        console.log("No more posts to load.");
        return;
      }

      if (page === 1) {
        dispatch(setPosts({ posts: data }));
        console.log("Posts set successfully.");
      } else {
        const newdata = [...posts, ...data];

        dispatch(setPosts({ posts: newdata }));
        console.log("Additional posts fetched and set successfully.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error.message);
      // Handle the error appropriately, such as displaying a message to the user or retrying the request.
    } finally {
      setLoading(false);
    }

    // Subscribe to socket events outside of try-catch block to ensure it always runs.
    socket.current.on("new_post", (post) => {
      console.log("New post received:", post);
      getPosts(); // Recursive call to update posts after a new post is received.
      console.log("Fetching posts after new post received.");
    });
  };
  const getUserPosts = async () => {
    try {
      const response = await fetch(`${host}/posts/${userId}/posts`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(
          "Failed to fetch user posts. Server responded with " +
            response.status +
            " " +
            response.statusText
        );
      }

      const data = await response.json();
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      dispatch(setPosts({ posts: data }));
      console.log("User posts fetched and set successfully.");
      // Subscribe to socket events outside of try-catch block to ensure it always runs.
      socket.current.on("new_post", (post) => {
        // console.log("New post received:", post);
        getUserPosts(); // Recursive call to update posts after a new post is received.
        // console.log("Fetching posts after new post received.");
      });
    } catch (error) {
      console.error("Error fetching user posts:", error.message);
      // Handle the error appropriately, such as displaying a message to the user or retrying the request.
    }
  };

  useEffect(() => {
    if (isProfile) {
      getUserPosts();
    } else {
      getPosts();
    }
  }, [page, userId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isSelectedSdgs && isSelectedSdgs.length > 0
        ? posts
            .filter((post) =>
              isSelectedSdgs.some((selectedSdg) =>
                post.sdgs.includes(selectedSdg)
              )
            )
            .map(
              ({
                _id,
                userId,
                firstName,
                lastName,
                description,
                location,
                picturePath,
                userPicturePath,
                likes,
                comments,
                userType,
                sdgs,
                title,
                summary,
                createdAt,
              }) => (
                <PostWidget
                  key={_id}
                  postId={_id}
                  postUserId={userId}
                  name={`${firstName} ${lastName}`}
                  description={description}
                  location={location}
                  picturePath={picturePath}
                  userPicturePath={userPicturePath}
                  likes={likes}
                  comments={comments}
                  userType={userType}
                  sdgs={sdgs[0]}
                  createdAt={createdAt}
                  title={title}
                  summary={summary}
                />
              )
            )
        : posts.map(
            ({
              _id,
              userId,
              firstName,
              lastName,
              description,
              location,
              picturePath,
              userPicturePath,
              likes,
              comments,
              userType,
              sdgs,
              createdAt,
              title,
              summary,
            }) => (
              <PostWidget
                key={_id}
                postId={_id}
                postUserId={userId}
                name={`${firstName} ${lastName}`}
                description={description}
                location={location}
                picturePath={picturePath}
                userPicturePath={userPicturePath}
                likes={likes}
                comments={comments}
                userType={userType}
                sdgs={sdgs[0]}
                createdAt={createdAt}
                title={title}
                summary={summary}
              />
            )
          )}
      {loading && <div>Loading...</div>}
    </>
  );
};

export default PostsWidget;
