import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { host } from "host";
import styles from "./SDGSelectionPage.module.css"; // Import the CSS module

const SDGSelectionPage = () => {
  const history = useNavigate();
  const [selectedSDGs, setSelectedSDGs] = useState([]);
  const [error, setError] = useState("");
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  const sdgs = [
    "No Poverty",
    "Zero Hunger",
    "Good Health and Well-being",
    "Quality Education",
    "Gender Equality",
    "Clean Water and Sanitation",
    "Affordable and Clean Energy",
    "Decent Work and Economic Growth",
    "Industry, Innovation, and Infrastructure",
    "Reduced Inequality",
    "Sustainable Cities and Communities",
    "Responsible Consumption and Production",
    "Climate Action",
    "Life Below Water",
    "Life on Land",
    "Peace and Justice Strong Institutions",
    "Partnerships to achieve the Goal",
  ];

  const handleSDGSelection = (sdg) => {
    const updatedSDGs = selectedSDGs.includes(sdg)
      ? selectedSDGs.filter((selectedSDG) => selectedSDG !== sdg)
      : [...selectedSDGs, sdg];
    setSelectedSDGs(updatedSDGs);
  };

  const handleSubmit = async () => {
    if (selectedSDGs.length < 3) {
      setError("Please select at least three SDGs.");
      return;
    }

    try {
      await axios.post(
        `${host}/users/sdg-selection`,
        { selectedSDGs, _id },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      history("/home");
    } catch (error) {
      setError("Failed to update SDGs. Please try again.");
    }
  };

  return (
    <div className={styles.sdgContainer}>
      <h2>Select at least three Sustainable Development Goals (SDGs)</h2>
      <div className={styles.sdgOptions}>
        {sdgs.map((sdg, index) => (
          <div key={index} className={styles.sdgOption}>
            <div className={styles.customCheckbox}>
              <input
                type="checkbox"
                id={sdg}
                value={sdg}
                checked={selectedSDGs.includes(sdg)}
                onChange={() => handleSDGSelection(sdg)}
              />
              <label htmlFor={sdg}></label>
            </div>
            <label htmlFor={sdg} className={styles.sdgLabel}>
              {sdg}
            </label>
          </div>
        ))}
      </div>
      <div className={styles.selectedSdgsContainer}>
        <div className={styles.selectedSdgs}>
          {selectedSDGs.map((sdg, index) => (
            <div key={index} className={styles.selectedSdg}>
              {sdg} <span onClick={() => handleSDGSelection(sdg)}>✕</span>
            </div>
          ))}
        </div>
        <button className={styles.submitButton} onClick={handleSubmit}>
          Submit
        </button>
      </div>
      {error && <p className={styles.errorMessage}>{error}</p>}
    </div>
  );
};

export default SDGSelectionPage;
