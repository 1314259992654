import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  user: null,
  token: null,
  posts: [],
  socket: null, // Add socket property to the initial state
  selectedSdgs: [], // Add selectedSdgs property to the initial state
  searchResults: [], // Add searchResults property to the initial state
  singlePost: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;
    },
    setFriends: (state, action) => {
      if (state.user) {
        state.user.friends = action.payload.friends;
      } else {
        console.error("user friends non-existent :(");
      }
    },
    setPosts: (state, action) => {
      state.posts = action.payload.posts;
    },
    setSinglePost: (state, action) => {
      state.singlePost = action.payload.singlePost;
    },
    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });
      state.posts = updatedPosts;
    },
    deletePostAction: (state, action) => {
      const postIdToDelete = action.payload; // Access payload directly without destructuring
      state.posts = state.posts.filter((post) => post._id !== postIdToDelete);
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setSelectedSdgs: (state, action) => {
      state.selectedSdgs = action.payload;
    },
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
  },
});

export const {
  setMode,
  setLogin,
  setLogout,
  setFriends,
  setPosts,
  setPost,
  deletePostAction,
  setSocket, // Include the setSocket action in exports
  setSelectedSdgs,
  setSearchResults,
  setSinglePost,
} = authSlice.actions;

export default authSlice.reducer;
